<template>
    <section :class="{'bg-slate-900 text-white': bgFill}">
        <UContainer :class="styleType">
            <slot/>
        </UContainer>
    </section>
</template>

<script setup>
const STYLES = {
    'base': 'py-12 lg:py-24',
    'mini': 'pt-12'
};

const {blockStyle, offset} = defineProps({
    bgFill: {
        type: Boolean,
        default: false
    },
    offset: {
        type: Boolean,
        default: true
    },
    blockStyle: {
        type: String,
        default: 'base'
    }
});

const styleType = computed(() => {
    return offset ? STYLES[blockStyle] : '';
});
</script>
